import React, { useContext, useEffect, useRef, useState } from "react";
import Typed from "typed.js";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { PopupButton } from "@typeform/embed-react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { AppContext } from "../components/AppContext";
import Text from "../components/Text";
import Header from "../components/Header";
import Button from "../components/Button";
import { OptionProp } from "../components/Select";
import useCookies from "../hooks/useCookies";
import Flex from "../components/Flex";
import Image from "../components/Image";
import Group from "../components/Group";
import Section from "../components/Section";
import "../styles/Home.scss";
import Input from "../components/Input";
import { VerticalDivider, HorizontalDivider } from "../components/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SplitView from "../components/SplitView";

// const defaultForm = {
//   name: "",
//   phone: "",
//   email: "",
//   market: "",
//   interestedIn: "",
//   consent: true
// };

const Container = styled(Flex)`
  font-size: 2.5rem;
  height: 100vh;
  width: 100vw;
  padding: 5rem 7.5vw 0;
  display: block;
  background-repeat: no-repeat;
  background-position-y: 15rem;
  background-size: cover;

  h2 {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 0;
    text-align: left;
  }
  h3 {
    font-weight: 300;
    font-size: 1.6rem;
    margin: 0;
  }
  video {
    margin: 2rem;
    max-width: 450px;
    border-radius: 0.5rem;
  }

  .first ${Text} {
    white-space: nowrap;
    font-size: 2.5rem;
  }

  #second h2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
    color: var(--tertiary);
  }
  .third ${Text} {
    font-size: 1.5rem;
  }
  .first {
    padding: 1rem;
    padding-left: 1rem;
    padding-bottom: 0;
  }
  .second,
  .third {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-top: 0.75rem;
    padding: 1rem;
    padding-left: 1rem;
  }
  .second {
    padding-top: 0;
    display: block;
  }
  .third {
    padding: 0rem;
  }
  ${Group} {
    :first-of-type {
      margin-top: 6rem;
    }
  }

  @media (max-width: 810px) {
    align-items: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
    background: none;
    h3 {
      font-size: 1.8rem;
      ${Text} {
        font-size: 1.8rem;
      }
    }
    #second {
      h2 {
        font-size: 1.5rem;
      }
      h3 {
        font-size: 1rem;
      }
      ${Text} {
        font-size: 1rem !important;
      }
    }
    .first {
      h1 {
        margin: 0;
        text-align: center;
        font-size: 2.2rem;
        line-height: 2.2rem;
      }
      ${Text} {
        white-space: initial;
        text-align: center;
        font-size: 2.2rem;
        padding-bottom: 0;
        line-height: 2.2rem;
      }
    }
    video {
      display: none;
    }
    .second {
      padding-bottom: 1rem;
    }
    .third ${Text} {
      padding-bottom: 1rem;
    }
    .second,
    .third {
      text-align: center;
    }
    .third {
      flex-direction: column;
    }
  }
`;

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  // centerMode: true,
  // centerPadding: "60px",
  rows: 1,
  // variableWidth: true,

  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
        // centerMode: true,
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false
        // centerPadding: "60px"
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
        centerPadding: "60px"
      }
    }
  ]
};

const Home = () => {
  const { getAllCookies } = useCookies();
  const { mobileSize } = useContext(AppContext);
  // const [isContactUs, setContactUs] = useState(false);
  const bodyRef = useRef<HTMLDivElement>();
  // const [, setThankYou] = useState(false);
  const [selectedMarket, setMarket] = useState("Not Specified");
  const [selectedService, setService] = useState("Real Estate Agent");
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");
  const history = useHistory();
  const cookies: Record<string, string> = {};

  const marketOptions: OptionProp[] = [
    { label: "Anywhere in the US", value: "anywhere" },
    { label: "Dallas, TX", value: "dallas-tx" },
    { label: "Galveston, TX", value: "galveston-tx" },
    { label: "Kissimmee, FL", value: "kissimmee-fl" },
    { label: "Big Bear Lake, CA", value: "big-bear-lake-ca" }
  ];
  const serviceOptions: OptionProp[] = [
    { label: "Real Estate Agent", value: "real_estate_agent" },
    { label: "Property Management", value: "property_management" },
    {
      label: "Cost Segregation Specialist",
      value: "cost_segregation_specialist"
    },
    { label: "Airbnb Deal Underwriting", value: "deal_underwriting" },
    { label: "Airbnb Listing Optimization", value: "listing_optimization" }
  ];
  // Create Ref element.
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Real Estate Agents",
        "Profitable Properties",
        "Acquisition Consultants",
        "Property Managers",
        "Free Airbnb Analysis Tools",
        "STR Lenders",
        "Cost Segregation Specialists",
        "Real Estate CPAs",
        "Airbnb Cleaners"
      ], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 200,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 50
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  getAllCookies().forEach(([cookieName, val]) => {
    cookies[cookieName] = val;
  });
  // const [form] = useState(defaultForm);
  // const { name, phone, email, market, interestedIn, consent } = form;

  const handleSelect = (value: any) => {
    if (marketOptions.some((element) => element.label === value.label)) {
      setMarket(
        value.label !== "Anywhere in the US" ? "Not Specified" : value.label
      );
    } else if (
      serviceOptions.some((element) => element.label === value.label)
    ) {
      setService(value.label);
    } else if (value.target.name === "email") {
      setEmail(value.target.value);
    } else if (value.target.name === "name") {
      setName(value.target.value);
    }
  };

  const handleSubmit = async () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(userEmail)) {
      alert("Please use a correct email address!");
      return;
    }

    try {
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: userName,
          sourcePage: "/"
        })
      };
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) button.disabled = true;
      await fetch(
        "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
        requestOptions
      ).then(() => {
        button.innerHTML = "&#10003;";
      });
    } catch (error) {
      console.error("error scheduling consultation:", error);
      const button = document.getElementById(
        "free-download"
      ) as HTMLButtonElement;
      if (button) {
        button.disabled = false;
        button.innerHTML = "Free Download Now";
      }
    }
  };

  // useEffect(() => {
  //   async function addContact() {
  //     try {
  //       const requestOptions = {
  //         method: "POST",
  //         body: JSON.stringify({
  //           name,
  //           email,
  //           phone,
  //           downloadReport: true,
  //           market,
  //           interested_in: interestedIn,
  //           consent,
  //           ...cookies,
  //           sourcePage: "download-report"
  //         })
  //       };
  //       const apiData = await fetch(
  //         "https://cbehz6zbjl.execute-api.us-west-2.amazonaws.com/v1/contacts",
  //         requestOptions
  //       );
  //       setThankYou(true);
  //     } catch (error) {
  //       console.error("error scheduling consultation:", error);
  //     }
  //     setContactUs(false);
  //   }
  //   if (isContactUs) {
  //     setContactUs(true);
  //     addContact();
  //   }
  // }, [isContactUs, form]);

  return (
    <div id="landing-page" style={{ overflow: "hidden" }}>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com" />
        <title>
          Chalet | Buy Profitable Short-Term Rentals With Confidence
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="The largest network of Airbnb realtors in the US. FREE Airbnb data tools and resources"
        />
        <meta
          property="og:title"
          content="Chalet | Buy and Own Profitable Short-Term Rentals "
        />
        <meta
          property="og:description"
          content="Short-term rental investor hub. Connect with
          a vast network of top-tier Airbnb realtors, property managers,
          CPAs and other STR service providers at No Cost"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://getchalet.com" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      {/* <Image
        src={`${process.env.PUBLIC_URL}/images/about2.webp`}
        alt="background"
        id="home-top__background"
        style={{
          width: "100%",
          maxWidth: "100%",
          position: "absolute",
          zIndex: "-1"
        }}
      /> */}
      <Container ref={bodyRef as any} id="home-top">
        {/* <Image
          src={`${process.env.PUBLIC_URL}/images/mountain_cabin.webp`}
          alt="Cabin"
          id="home-top__main-img"
          style={{
            width: "100%",
            maxWidth: "100%",
            position: "absolute",
            zIndex: "-1"
          }}
        /> */}
        <iframe
          src="https://go.screenpal.com/watch/cZ1TDkV8lHK"
          frameBorder="0"
          allowFullScreen
          style={{
            display: mobileSize ? "none" : "block",
            position: "absolute",
            top: "30%",
            right: "10%",
            width: "500px",
            height: "280px"
            // width: "500px",
            // height: "500px"
          }}
          title="Embedded Video"
        />
        {/* <Image
          src={`${process.env.PUBLIC_URL}/images/sun1.webp`}
          alt="Decoration"
          style={{
            position: "absolute",
            left: "0",
            top: "30%"
          }}
        /> */}
        <div id="top_content">
          <div id="first">
            <h1
              style={{
                lineHeight: "140%",
                fontWeight: 500,
                fontSize: "1.8rem",
                color: "var(--tertiary)",
                marginBottom: "0"
              }}
            >
              We Connect Short-Term Rental Investors with...
            </h1>
            {/* <br style={{ display: mobileSize ? "initial" : "none" }} /> */}
            <span
              style={{
                color: "var(--quinary)",
                // display: mobileSize ? "block" : "inline-block",
                // background: "var(--white)",
                paddingLeft: mobileSize ? 0 : "0rem"
                // display: "block"
              }}
              ref={el}
            />
            {/* Element to display typing strings */}
          </div>
          <div id="second">
            {/* <h2
              style={{
                lineHeight: "140%",
                color: "var(--tertiary)"
              }}
            >
              We are here to simplify and streamline your short-term rental
              investment and management business
            </h2> */}
            <h2
              style={{
                lineHeight: "140%",
                color: "var(--tertiary)"
                // display: mobileSize ? "block" : "inline-block",
              }}
            >
              We&apos;ve researched and vetted the top short-term rental tools
              and vendors to connect investors with the best in the business,
              completely free of charge
            </h2>
          </div>
          <div
            id="third"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: "1rem"
            }}
          >
            <PopupButton
              id="SEmI3KLE"
              shareGaInstance="true"
              hidden={{
                source_page: "/(banner-chat-with-chalet-str-expert)"
              }}
              data-id="talk-to-str-expert"
              className="talk-to-str-expert_button_top btn-consult"
              style={{ flex: mobileSize ? "1 0 100%" : "0 1 auto" }}
            >
              Connect With STR Vendor
            </PopupButton>
            <Button
              className="airbnbs-for-sale-button btn-consult"
              data-id="header-best-airbnbs"
              style={{
                background: "var(--tertiary)",
                color: "var(--white)",
                fontSize: "1rem",
                fontWeight: "400",
                textAlign: "center",
                textTransform: "uppercase",
                flex: mobileSize ? "1 0 100%" : "0 1 auto"
              }}
              onClick={() => history.push("/airbnbs-for-sale")}
            >
              Airbnbs For Sale
            </Button>
          </div>
          {/* <div id="third">
            I am looking for a
            <Dropdown
              options={serviceOptions}
              icon={`${process.env.PUBLIC_URL}/images/Polygon2.webp`}
              onSelect={handleSelect}
            />
            in
            <Dropdown
              options={marketOptions}
              icon={`${process.env.PUBLIC_URL}/images/Polygon2.webp`}
              onSelect={handleSelect}
            />
            <PopupButton
              id="ZbQsTdlZ"
              shareGaInstance="true"
              hidden={{
                market_of_interest: selectedMarket,
                services: selectedService,
                source_page: "/"
              }}
              data-id="talk-to-str-expert"
              className="talk-to-str-expert_button_top"
            >
              Connect ASAP with STR Vendors
            </PopupButton>
          </div> */}
        </div>
        <div style={{ display: "none", maxWidth: "50%" }}>
          <Image
            src={`${process.env.PUBLIC_URL}/images/partner-realtor-musculine.webp`}
            alt="Chalet Partner Real Estate Agents With STR Investing Expertise"
            $mobileSize={mobileSize}
            style={{
              gridRowStart: mobileSize ? "1" : "inherit",
              maxWidth: mobileSize ? "100%" : "300px",
              display: mobileSize ? "none" : "block",
              borderRadius: "0.25rem"
            }}
          />
        </div>
      </Container>
      <Section
        id="home-statistics"
        style={{
          backgroundImage: `url('${process.env.PUBLIC_URL}/images/radial-gradient.webp')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="stats">
          <span id="alt-red">Connected Investors With</span>
          <span id="highlight">100+million</span>
          <span id="alt-black">in short-term rental assets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-black">Short-Term Rental Partners</span>
          <span id="highlight">All 50 states</span>
          <span id="alt-black">200+ markets</span>
        </div>
        <VerticalDivider name="verdiv" />
        <HorizontalDivider name="hordiv" />
        <div className="stats">
          <span id="alt-red">Analyzing</span>
          <span id="highlight">1 million+</span>
          <span id="alt-red">short-term rentals</span>
        </div>
      </Section>
      <Section id="home-about">
        <Image
          src={`${process.env.PUBLIC_URL}/images/about-deco-top.webp`}
          alt="decoration"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            margin: "-3rem 0 0 -1rem"
          }}
        />
        <Header size={2}>What is Chalet?</Header>
        <div id="home-about__desc">
          <Text className="about-text">
            Chalet is a resource hub for anyone on a short-term rental journey.
            Our hub offers a combination of{" "}
            <span className="bold">short-term rental tools</span> and{" "}
            <span className="bold">service providers.</span>
          </Text>
          <hr className="hr" />
          <Text className="about-text">
            With Chalet by your side, you can navigate the complexities of
            regulations, identify lucrative markets, select high return
            properties, implement effective management strategies, optimize tax
            strategies, and much more.
          </Text>
          <hr className="hr" />
          <Text className="about-text">
            As your dedicated guide, we simplify and streamline the entire
            process, acting as your mentors and connecting you with a vast
            network of trusted service providers.
          </Text>
        </div>
        {/* <div id="div-outside">
          {Bulletpoint(
            "REAL ESTATE AGENTS WITH AIRBNB RENTAL EXPERTISE",
            "quaternary",
            "one",
            "/real-estate-agents"
          )}
        </div>
        <div id="home-about__service">
          <div id="div-top">
            <div id="div-eight">
              {Bulletpoint("REAL ESTATE CPAs", "tertiary", "eight")}
            </div>
            <div>
              {Bulletpoint("STR DEAL UNDERWRITING", "quaternary", "seven")}
            </div>
            <div>
              {Bulletpoint("AIRBNB LISTING OPTIMIZATIONS", "tertiary", "six")}
            </div>
          </div>
          <Image
            src={`${process.env.PUBLIC_URL}/images/sunrise.webp`}
            alt="Sunrise"
          />
          <div id="div-bot">
            <div>
              {Bulletpoint(
                "PROPERTY MANAGERS",
                "tertiary",
                "two",
                "/property-management"
              )}
            </div>
            <div>
              {Bulletpoint(
                "FREE SHORT TERM RENTAL TOOLS",
                "quaternary",
                "three",
                "/analyze"
              )}
            </div>
            <div>
              {Bulletpoint(
                "COST SEGREGATION SPECIALISTS",
                "tertiary",
                "four",
                "/cost-segregation"
              )}
            </div>
          </div>
        </div>
        <div id="div-outside">
          {Bulletpoint(
            "NETWORK WITH OTHER INVESTORS (coming soon)",
            "quaternary",
            "five"
          )}
        </div> */}
        {/* End of improvement requirement */}
        <Image
          src={`${process.env.PUBLIC_URL}/images/about-deco-bottom.webp`}
          alt="decoration"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            margin: "0 -0.3rem -3rem 0"
          }}
        />
      </Section>
      <Section
        id="home-guide"
        style={{
          boxShadow: "none",
          background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >
        <Header size={2}>Who We Help?</Header>
        {/* <Image
          src={`${process.env.PUBLIC_URL}/images/yup.webp`}
          alt="decoration"
          style={{
            marginTop: "-9rem",
            width: "fit-content",
            paddingLeft: "5rem"
          }}
        /> */}
        <Image
          src={`${process.env.PUBLIC_URL}/images/yup-left.webp`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            left: 0,
            marginTop: "3rem"
          }}
        />
        <Image
          src={`${process.env.PUBLIC_URL}/images/yup-right.webp`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            right: 0,
            marginTop: "3rem"
          }}
        />
        <div id="home-about__split">
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/dall-e-one.webp`}
              alt="First"
            />
            <div className="text">
              <Header size={2}>New Real Estate Investors</Header>
              <Text>
                Dive into the STR market with confidence. We provide the
                foundational support you need to get started.
              </Text>
            </div>
          </div>
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/dall-e-two.webp`}
              alt="Second"
            />
            <div className="text">
              <Header size={2}>
                Seasoned Investors New to Short-Term Rentals
              </Header>
              <Text>
                Are you an experienced long-term or commercial investor looking
                to get into STRs? Transition smoothly into STR with our
                specialized guidance, leveraging your existing investment
                experience.
              </Text>
            </div>
          </div>
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/retro-chalet.webp`}
              alt="Third"
            />
            <div className="text">
              <Header size={2}>Veteran Short-Term Rental Investors </Header>
              <Text>
                Looking to expand into a new STR market? We streamline your
                transition by quickly connecting you with our local STR experts,
                saving you valuable time and effort.
              </Text>
            </div>
          </div>
          <div>
            <Image
              src={`${process.env.PUBLIC_URL}/images/fort-lauderdale.webp`}
              alt="Fourth"
            />
            <div className="text">
              <Header size={2}>Enterprise or International Investors</Header>
              <Text>
                Access customized solutions for large-scale or cross-border STR
                investments, designed for substantial and diverse portfolios.
              </Text>
            </div>
          </div>
        </div>
        <PopupButton
          id="ZbQsTdlZ"
          shareGaInstance="true"
          hidden={{
            market_of_interest: selectedMarket,
            services: selectedService,
            source_page: "/"
          }}
          data-id="talk-to-str-expert"
          className="talk-to-str-expert_button_top btn-consult"
        >
          Book a free consult with a str expert
        </PopupButton>
      </Section>
      <Section
        style={{
          boxShadow: "none",
          // background: `url("${process.env.PUBLIC_URL}/images/blue-fade.webp")`,
          // background: `url("${process.env.PUBLIC_URL}/images/about2.webp")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          maxWidth: "100%",
          padding: 0,
          position: "relative"
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/testimonial-element.png`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            right: "15rem",
            top: "1rem",
            display: mobileSize ? "none" : "block"
          }}
        />
        <Image
          src={`${process.env.PUBLIC_URL}/images/testimonial-element.png`}
          alt="decoration"
          style={{
            width: "fit-content",
            position: "absolute",
            left: "15rem",
            top: "1rem",
            display: mobileSize ? "none" : "block"
          }}
        />
        <Header size={2} style={{ margin: "2rem auto 0" }}>
          Testimonials
        </Header>
        <div
          style={{
            width: "100%",
            margin: "0 auto",
            display: "block"
          }}
        >
          <Slider {...sliderSettings}>
            <div
              className="testimonial"
              style={
                {
                  // background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
                }
              }
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Justin B.
              </Header>
              <SplitView split="2:3">
                <Image
                  src="https://a0.muscache.com/im/pictures/prohost-api/Hosting-1067591824691586398/original/bce45fe4-ec7e-4cd2-b3a5-320904fea506.jpeg?im_w=1200"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.7rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    My wife and I found Chalet when we were in search of a short
                    term rental property, but needed some professional help.
                    Chalet team replied back quickly and referred me to a local
                    agent in the market where we were searching. It was a huge
                    help because our agent was well versed in investment
                    properties and provided immediate value in helping us find
                    something specific in our market.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    In the end, we closed on the property and have been
                    operating it for a few months now! It&apos;s been a great
                    couple of months and we couldn&apos;t be happier with the
                    way it all came together....
                    <Text
                      style={{
                        fontSize: "1.5rem",
                        lineHeight: "1.5rem",
                        fontWeight: 400
                      }}
                    >
                      and it all started with Chalet!
                    </Text>
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/1067591824691586398"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Justin&apos;s home
              </a>
            </div>
            <div
              className="testimonial"
              style={{
                background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
              }}
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Jimmy O.
              </Header>
              <SplitView>
                <Image
                  src="https://a0.muscache.com/im/pictures/hosting/Hosting-U3RheVN1cHBseUxpc3Rpbmc6MTAyNDA5NjMzMjEwNTM3NzgyNA%3D%3D/original/aad2a319-59e6-40c1-91fb-1b831e327143.jpeg?im_w=1440"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.5rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    Chalet hooked me up with that amazing realtor in Tampa with
                    great STR knowledge. Plus, they set me up with some folks
                    for a Cost Segregation study that saved me a ton on taxes.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    Seriously, their service is top-notch.
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/1024096332105377824"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Jimmy&apos;s home
              </a>
            </div>
            <div
              className="testimonial"
              style={{
                background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`
              }}
            >
              <Header
                size={3}
                // color={Color.quinary}
                style={{ paddingBottom: "1rem", textAlign: "center" }}
              >
                Dorinda A.
              </Header>
              <SplitView>
                <Image
                  src="https://a0.muscache.com/im/pictures/miso/Hosting-564716174289419914/original/9ecc5107-abe9-4729-bee6-d3c1f298fb64.jpeg?im_w=1440"
                  alt="Airbnb & VRBO listings setup "
                  style={{
                    borderRadius: "0.5rem",
                    maxWidth: mobileSize ? "100%" : "500px",
                    objectFit: "cover",
                    margin: "0rem auto",
                    display: "block",
                    height: "auto",
                    // maxHeight: mobileSize ? "100%" : "60px",
                    // minHeight: mobileSize ? "100%" : "60px",
                    // width: "100%",
                    verticalAlign: "middle"
                  }}
                  $mobileSize={mobileSize}
                />
                <div>
                  <Text
                    block
                    style={{
                      fontSize: "1.5rem",
                      // lineHeight: "1.5rem",
                      paddingBottom: "1rem"
                    }}
                  >
                    My husband and I had a dream of moving to a an active,
                    outdoors community for our retirement. But, instead of just
                    buying an expensive home, we needed a property that could
                    also provide flexible income. That is where Chalet stepped
                    in. Their comprehensive data research was compelling. But,
                    the concierge, personal approach was out of this world. They
                    used our personal preferences to hone in on neighborhoods
                    that suited us. Then, they matched us with an amazing
                    realtor, who found the actual home that was perfect for us.
                  </Text>
                  <Text block style={{ fontSize: "1.5rem" }}>
                    Even after we bought the house, Chalet continues to provide
                    competent service providers. We have used them to find our
                    cleaning service, handyman services, etc. I would highly
                    recommend Chalet to help you with your investment decisions.
                  </Text>
                </div>
              </SplitView>
              <a
                href="https://www.airbnb.com/rooms/564716174289419914"
                style={{
                  display: "block",
                  margin: "1rem auto",
                  color: "var(--white)",
                  borderRadius: "0.5rem",
                  textDecoration: "none",
                  textAlign: "center",
                  padding: "0.5rem",
                  background: "var(--linearGradient)"
                }}
              >
                Book a stay at Dorinda&apos;s home
              </a>
            </div>
          </Slider>
        </div>
      </Section>
      <Section
        id="home-markets"
        style={{
          background: `url("${process.env.PUBLIC_URL}/images/markets-bg.webp")`,
          backgroundSize: "cover"
        }}
      >
        <Image
          src={`${process.env.PUBLIC_URL}/images/decoration-lcorner.webp`}
          alt="decoration"
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            marginTop: "-2rem"
          }}
        />
        <Image
          src={`${process.env.PUBLIC_URL}/images/decoration-rcorner.webp`}
          alt="decoration"
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            marginTop: "-1rem"
          }}
        />
        <Header size={2} id="home-markets__header">
          Airbnb Rentals Top 150 Markets
        </Header>
        <Text id="home-markets__description">
          Instantly compare the{" "}
          <span style={{ color: "var(--quinary)" }}>
            top 150 short-term (Airbnb) rental markets
          </span>{" "}
          in the US by returns on investment, occupancy rates, revenue, home
          price, and more…
        </Text>
        <div id="home-markets__main">
          <div id="left-main">
            <Image
              src={`${process.env.PUBLIC_URL}/images/chalet_markets_2024.webp`}
              alt="Market Comparison guide"
            />
          </div>
          <div id="right-main">
            <Input onChange={handleSelect} name="name" placeholder="Name" />
            <Input onChange={handleSelect} name="email" placeholder="Email" />
            <Text id="disclaimer">
              By signing up, you agree to receive subsequent email and
              third-party marketing communications from Chalet pursuant to our
              Privacy Policy, which you may opt out of, or unsubscribe from at
              any time.
            </Text>
            <Button id="free-download" onClick={handleSubmit}>
              Free download now
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Home;
