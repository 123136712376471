import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Amplify, { Auth, Hub } from "aws-amplify";
import styled from "styled-components";
import { BrowserRouter } from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import Footer from "./components/Footer";
import useCookies from "./hooks/useCookies";
import awsconfig from "./aws-exports";
import { AppContext } from "./components/AppContext";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import Routes from "./Routes";
import LoginModal from "./pages/modals/LoginModal";
import ContactUsModal from "./pages/modals/ContactUsModal";
import CostSegModal from "./pages/modals/CostSegModal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Add this import

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [productionRedirectSignIn, localRedirectSignIn] =
  awsconfig.oauth.redirectSignIn.split(",");

const [productionRedirectSignOut, localRedirectSignOut] =
  awsconfig.oauth.redirectSignOut.split(",");

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        ),
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut.replace(
          "https://getchalet.com",
          "https://www.getchalet.com"
        )
  },
  ssr: true
});

const StyledApp = styled.div`
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: 200;
  }
  h6 {
    font-weight: 200;
    font-size: 1rem;
    padding: 1rem;
  }
  @media (max-width: 800px) {
    .CookieConsent {
      display: block !important;
      // bottom: 2rem !important;
      div {
        margin: 1rem !important;
        flex: 0 !important;
      }
    }
  }
`;
function App() {
  const { getAllCookies } = useCookies();
  const cookies: Record<string, string> = {};
  getAllCookies().forEach(([name, val]) => {
    cookies[name] = val;
  });
  const { setUser, user } = useContext(AppContext);
  const [formData, setFormData] = useState();
  useEffect(() => {
    console.log("just triggering a change");
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn": {
          setUser(data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }

          break;
        }
        case "signOut":
          setUser(undefined);
          break;
        case "customOAuthState": {
          setFormData(JSON.parse(data));
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
          break;
        }
        default:
          break;
      }

      switch (event) {
        default: {
          // eslint-disable-next-line no-console
          // eslint-disable-next-line
          console.log("Hub", data);
          const prevLocation = localStorage.getItem("prevLoc");

          if (prevLocation && prevLocation !== "undefined") {
            window.location.replace(prevLocation);
            localStorage.removeItem("prevLoc");
          }
        }
      }
    });
    Auth.currentAuthenticatedUser()
      .then((newUser) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
        console.log({ user: newUser });
        setUser(newUser);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        // eslint-disable-next-line
      console.log(err);
      });
  }, []);

  useEffect(() => {
    async function updateUser() {
      await Auth.updateUserAttributes(user, formData as any);
    }
    if (formData && user) {
      updateUser();
    }
  }, [formData, user]);

  useEffect(() => {
    const font = new FontFaceObserver("Oswald", {
      weight: 400
    });

    font.load().then(() => {
      document.body.classList.add("font-loaded");
    });
  }, []);

  return (
    <StyledApp>
      <Helmet>
        <link rel="canonical" href="https://www.getchalet.com" />
        <title>
          Chalet | Short-Term &amp; Vacation Rental Investment Marketplace
        </title>
        <meta property="og:type" content="website" />
        <meta
          name="description"
          content="Chalet is the largest resource hub for everything short-term rentals.
              Find Airbnb Realtors and other STR Service providers. Invest in Short-Term (Airbnb) rentals with confidence"
        />
        <meta
          property="og:title"
          content="Chalet | Short-Term &amp; Vacation Rental Investment Marketplace"
        />
        <meta
          property="og:description"
          content="Chalet empowers investors with data insights and industry experts.
              Find Realtors, Property Managers, Airbnb laws, and other resources with our all-in-one platform."
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://getchalet.com" />
        <meta property="og:site_name" content="Chalet" />
      </Helmet>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* <RouterScrollToTop /> */}
        <Navbar />
        <Hero />
        <Routes />
        {/* <Delayed> */}
        {/* <div> */}
        {/* {!("cookies" in cookies) && <CookieConsentModal />} */}
        {/* </div> */}
        {/* </Delayed> */}
        <ContactUsModal />
        <CostSegModal />
        <LoginModal />
        <Footer />
      </BrowserRouter>
      {/* <ScrollToTop /> */}
    </StyledApp>
  );
}

export default App;
