import React, { useState, useEffect } from "react";

interface ImageGalleryModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  currentIndex: number;
  onPrev: () => void;
  onNext: () => void;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  isOpen,
  onClose,
  images,
  currentIndex,
  onPrev,
  onNext
}) => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      // Load current image and adjacent images
      const imagesToLoad = [
        images[currentIndex],
        images[(currentIndex + 1) % images.length],
        images[(currentIndex - 1 + images.length) % images.length]
      ];

      setLoadedImages((prev) =>
        Array.from(new Set([...prev, ...imagesToLoad]))
      );
    }
  }, [isOpen, currentIndex, images]);

  if (!isOpen) return null;

  return (
    <div
      className="image-gallery-modal-overlay"
      role="dialog"
      aria-modal="true"
    >
      <div className="image-gallery-modal-content">
        <button
          className="close-button"
          onClick={onClose}
          onKeyDown={(e) => {
            if (e.key === "Escape") onClose();
          }}
          type="button"
          aria-label="Close modal"
        >
          &times;
        </button>
        {loadedImages.includes(images[currentIndex]) ? (
          <img src={images[currentIndex]} alt="" className="gallery-image" />
        ) : (
          <div className="image-placeholder">Loading...</div>
        )}
        <button
          className="nav-button prev"
          onClick={onPrev}
          type="button"
          aria-label="Previous image"
        >
          &lt;
        </button>
        <button
          className="nav-button next"
          onClick={onNext}
          type="button"
          aria-label="Next image"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default ImageGalleryModal;
